import * as React from 'react';
import Layout from '../components/Shared/layoutWhite';
import Home from './home';

const IndexPage = () => {
  return (
    <Layout>
      <Home></Home>
    </Layout>
  );
};

export default IndexPage;
